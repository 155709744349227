// Entry point for the build script in your package.json
import { Loader } from "@googlemaps/js-api-loader";
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import * as bootstrap from "bootstrap";
import Autocomplete from "bootstrap5-autocomplete";
import GLightbox from "glightbox";
import "./controllers";

ActiveStorage.start();

const init = () => {
  new bootstrap.Tooltip(document.body, {
    selector: '[data-bs-toggle="tooltip"]',
  });

  document
    //.getElementsByTagName("body")[0]
    .addEventListener("scroll", function () {
      var top = document.scrollingElement?.scrollTop;
      for (const e of document.getElementsByClassName("menubar"))
        (e as HTMLElement).style["top"] = top + "px";
    });

  document
    .querySelectorAll<HTMLElement>('[data-deselect-all="checkbox"]')
    .forEach((e) => {
      const selector = e.dataset["selector"];
      selector &&
        e.addEventListener("click", (ev: Event) => {
          document
            .querySelectorAll<HTMLInputElement>(selector)
            .forEach((input) => (input.checked = false));
          ev.preventDefault();
        });
    });

  document.querySelectorAll<HTMLElement>("[data-navigate-to]").forEach((e) => {
    const path = e.dataset["path"];
    if (!path) return;
    e.addEventListener("click", (ev: Event) => {
      window.location = path as any;
      ev.preventDefault();
    });
  });

  Autocomplete.init("input.autocomplete", {
    highlightTyped: true,
    clearControl: ".clearautocomplete",
  });

  document
    .querySelectorAll<HTMLInputElement>(".autoselectonenter")
    .forEach((e) => {
      e.addEventListener("mouseup", () => {
        e.select();
      });
    });

  document
    .querySelectorAll<HTMLButtonElement>(".compilewithgps")
    .forEach((e) => {
      e.addEventListener("click", () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;
              console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
              const input = e.parentNode?.querySelector("input");
              if (input) input.value = `${latitude}; ${longitude}`;
              else console.error("Cannot find input");
            },
            (error) => {
              alert(
                (error.constructor?.name ??
                  "Impossibile accedere alle coordinate.") +
                  " " +
                  (error?.message ?? "Motivo sconosciuto")
              );
            },
            { enableHighAccuracy: true }
          );
        } else {
          alert("Geolocation is not supported by this browser.");
        }
      });
    });

  const gmapApiKey = document.head.querySelector<HTMLMetaElement>(
    "meta[name=gmap-api-key]"
  )?.content;

  const loader = gmapApiKey
    ? new Loader({
        libraries: ["marker", "maps"],
        apiKey: gmapApiKey,
        version: "weekly",
      })
    : undefined;

  loader?.importLibrary("maps").then(async (lib) => {
    document
      .querySelectorAll<HTMLElement>(".map-container")
      .forEach((container) => {
        const resize = () =>
          (container.style.height = container.clientWidth * 0.8 + "px");
        new ResizeObserver(resize).observe(container);
        const mapEl = container.querySelector<HTMLElement>(".map");
        if (!mapEl) return;
        const lat = mapEl.dataset["latitude"];
        const lng = mapEl.dataset["longitude"];
        if (!lat || !lng) return;
        const center = {
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        };
        const gmap = new lib.Map(mapEl, {
          zoom: 10,
          center: center,
          mapId: "xxx",
          mapTypeId: "terrain",
          streetViewControl: false,
        });
        new google.maps.marker.AdvancedMarkerElement({
          position: center,
          map: gmap,
        });
      });
  });

  document
    .querySelectorAll<HTMLFormElement>(".images-upload-form")
    .forEach((e) => {
      const fileInput = e.querySelector<HTMLInputElement>("input[type=file]");
      if (!fileInput) return;
      const updateSubmitButton = () => {
        const submit = e.querySelector<HTMLInputElement>("input[type=submit]");
        const empty = fileInput.files?.length == 0;
        const v = empty ? "none" : "inline-block";
        console.log(fileInput.files, empty, v);
        if (submit) submit.style.display = v;
      };
      fileInput.addEventListener("change", updateSubmitButton);
      updateSubmitButton();
    });

  GLightbox({});

  document
    .querySelectorAll<HTMLInputElement>('[data-taxa-role="name-provider"]')
    .forEach((e) => {
      const receiverSelector = e.dataset["taxaReceiverSelector"];
      const receivers = receiverSelector
        ? document.querySelectorAll<HTMLInputElement>(receiverSelector)
        : undefined;

      Autocomplete.getOrCreateInstance(e).setConfig(
        "onSelectItem",
        (item: any) =>
          receivers?.forEach((r) => {
            r.innerHTML = item.full_name;
          })
      );
    });
};

document.addEventListener("turbo:render", init);
document.addEventListener("turbo:load", init);

// document.addEventListener("turbo:load", console.log);
// document.addEventListener("turbo:page-mprph", console.log);
// document.addEventListener("turbo:frame-load", console.log);
// document.addEventListener("turbo:frame-render", console.log);
// document.addEventListener("turbo:before-fetch-response", console.log);
// document.addEventListener("turbo:morph-element", console.log);
