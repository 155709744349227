import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="uploads"
export default class extends Controller {
  static targets = [
    "uploadProgress",
    "uploadProgressWrapper",
    "uploadFileName",
  ];

  declare uploadProgressWrapperTarget: HTMLElement;
  declare uploadProgressTarget: HTMLDivElement;
  declare uploadFileNameTarget: HTMLElement;

  initializeDirectUpload() {
    this.uploadProgressWrapperTarget.classList.remove("d-none");
  }

  startDirectUpload(event) {
    this.uploadProgressTarget.classList.add("notransition");
    this.update(event);
  }

  progressDirectUpload(event) {
    this.uploadProgressTarget.classList.remove("notransition");
    this.update(event);
  }

  update(event) {
    console.log(event);
    const { progress, file } = event.detail;
    const p = progress ?? 0;
    console.log("updateing width", p);
    this.uploadProgressTarget.style.width = `${p}%`;
    this.uploadFileNameTarget.textContent = file.name;
  }
}
